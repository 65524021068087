import * as React from "react";
import Layout from "../components/layout";
import Hero from "../components/hero";
import Articles from "../components/articles";
import Seo from "../components/seo";
// markup
const IndexPage = () => {
  return (
    <Layout>
      <Seo
        title="Gulam Hussain | Front end developer"
        description="A Front end developer, I write about ReactJS, vanilla Js and front end development in general"
      />
      <Hero />
      <Articles />
    </Layout>
  );
};

export default IndexPage;
