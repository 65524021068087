import React from "react";
import styled from "styled-components";

const Hero = styled.section`
  margin: 4rem 0;
  padding-left: 0.6rem;
`;
const HeroHeading = styled.h1`
  line-height: 3rem;
`;
function Header(props) {
  return (
    <Hero role="presentation">
      <HeroHeading>
        Hi, I'm Gulam Hussain. A front-end developer and occasional writer.
      </HeroHeading>
    </Hero>
  );
}
export default Header;
