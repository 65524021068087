import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql, Link } from "gatsby";
import Box from "../Box";
const ArticleContainer = styled.div`
  margin: 1rem 0;
`;

const LinkHeading = styled.h3`
  margin: 0;
`;

const ArticleLink = styled(Link)`
  display: block;
  margin: 1rem 0;
  color: #222;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

function Articles(props) {
  const { allMdx } = useStaticQuery(graphql`
    {
      allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
        edges {
          node {
            excerpt
            slug
            timeToRead
            frontmatter {
              date(formatString: "DD MMMM, YYYY")
              title
              tags
              categories
              description
            }
          }
        }
      }
    }
  `);
  return (
    <ArticleContainer>
      <h2 style={{ color: "yellowgreen" }}>Blog</h2>
      {allMdx.edges.map((node, index) => {
        const article = node.node;
        return (
          <Box key={index} style={{ marginBottom: "2.5rem" }}>
            <ArticleLink key={index} to={article.slug}>
              <LinkHeading>{article.frontmatter.title}</LinkHeading>
            </ArticleLink>
            {/* <p>{article.timeToRead} minutes</p> */}
          </Box>
        );
      })}
    </ArticleContainer>
  );
}
export default Articles;
